var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "form-wrapper",
      attrs: { "auto-complete": "on", model: _vm.form, "label-width": "160px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "Notes", prop: "notes" } },
        [
          _c("el-input", {
            attrs: { type: "textarea", rows: 2 },
            model: {
              value: _vm.form.notes,
              callback: function ($$v) {
                _vm.$set(_vm.form, "notes", $$v)
              },
              expression: "form.notes",
            },
          }),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-form-item",
        { attrs: { label: "Start date", prop: "start_date" } },
        [
          _c("el-date-picker", {
            attrs: {
              type: "date",
              "picker-options": _vm.pickerOptions,
              format: "dd/MM/yyyy",
            },
            model: {
              value: _vm.form.start_date,
              callback: function ($$v) {
                _vm.$set(_vm.form, "start_date", $$v)
              },
              expression: "form.start_date",
            },
          }),
          _vm.start_diff
            ? _c("span", [
                _c("strong", [_vm._v(_vm._s(_vm.start_diff))]),
                _vm._v(
                  " " +
                    _vm._s(_vm._f("pluralize")("day", _vm.start_diff)) +
                    " away "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Deadline", prop: "deadline_date" } },
        [
          _c("el-date-picker", {
            attrs: {
              type: "date",
              "picker-options": _vm.pickerOptions,
              format: "dd/MM/yyyy",
            },
            model: {
              value: _vm.form.deadline_date,
              callback: function ($$v) {
                _vm.$set(_vm.form, "deadline_date", $$v)
              },
              expression: "form.deadline_date",
            },
          }),
          _vm.deadline_diff
            ? _c("span", [
                _c("strong", [_vm._v(_vm._s(_vm.deadline_diff))]),
                _vm._v(
                  " " +
                    _vm._s(_vm._f("pluralize")("day", _vm.deadline_diff)) +
                    " away "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-button",
        { attrs: { disabled: _vm.creating }, on: { click: _vm.handleSubmit } },
        [_vm._v(" Create ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }